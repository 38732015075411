import React from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Alert from '../../../helpers/Alert'
import Apis from '../../../api'
import swal from 'sweetalert'
import validator from 'validator'
import Modal from 'react-bootstrap/Modal'
import { default as ReactSelect } from "react-select"
import CollaboratorApi from '../ProjectCollaborator/CollaboratorApi'

function AddSubAdmin() {
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [values, setValues] = React.useState({
        id: '',
        first_name: '',
        last_name: '',
        member_abrv: '',
        member_handle: '',
        email: '',
        role: 'sub_admin',
        username: '',
        password: '',
        system_password: '',
        member_id: '',
        set_admin_as_member: false
    })
    const [refresh, setRefresh] = React.useState(false)
    const [show_add_sub_admin, setShowAddSubAdmin] = React.useState(false)
    const [show_existing_member, setShowExistingMember] = React.useState(false)
    const [subAdminList, setSubAdmin] = React.useState([])
    const [memberList, setMember] = React.useState([])
    const [member_List, seMemberLists] = React.useState([])

    React.useEffect(() => {
        Apis.getSubAdmin().then((res) => {
            var response = res.data.data;
            setSubAdmin(response.subAdmin)
        }).catch((error) => {
            console.log(error.response)
        })
        Apis.getMember().then((res) => {
            setMember(res.data.data)
        }).catch((error) => {
            console.log(error)
        })
        setTimeout(() => {
            setError({
                ...error,
                open: false
            })
        }, 1000)

    }, [refresh])

    React.useEffect(() => {
        let demo
        let list = []
        if (memberList.length > 0) {
            const members = memberList.member
            members.map((elm) => {
                if (elm.role === 'member' && elm.status === 1) {
                    demo = {
                        value: elm._id,
                        label: elm.first_name + ' ' + elm.last_name
                    }
                    list.push(demo);
                    return list
                }
                return list
            })
        }
        seMemberLists(list)
    }, [memberList])

    const handleDeleteSubAdmin = (id, status) => {
        let title = ''
        let message = ''
        if (status === 0) {
            title = "Are you sure want to move it to archive?"
            message = "Sub Admin archive"
        } else {
            title = "Are you sure want to restore?"
            message = "Sub Admin restore"
        }
        swal({
            title: title,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (id === '' || id === undefined) {
                    setError({
                        ...error,
                        error: 'Something went wrong !',
                        color: 'error',
                        open: true
                    })
                } else {                    
                    const raw = {
                        'id': id,
                        'role': 'member',
                        'password': '',
                        'system_password': '',
                        'set_admin_as_member': true
                    }
                    Apis.convertToSubAdmin(raw).then((res) => {
                        setError({
                            ...error,
                            error: 'Remove Sub-admin',
                            color: 'success',
                            open: true
                        })
                        setRefresh(!refresh);
                    }).catch((error) => {
                        setError({
                            ...error,
                            error: error.response.data.error,
                            color: 'error',
                            open: true
                        })
                    })
                }
            }
        })
    }

    const handleSubAdminSubmit = (formdata) => {

        if (formdata.first_name === '') {
            setError({
                ...error,
                error: 'First name is required',
                color: 'error',
                open: true
            })
        } else if (formdata.last_name === '') {
            setError({
                ...error,
                error: 'Last name is required',
                color: 'error',
                open: true
            })
        } else if (formdata.member_abrv === '') {
            setError({
                ...error,
                error: 'Sub Admin Abrv is required',
                color: 'error',
                open: true
            })
        } else if (formdata.member_handle === '') {
            setError({
                ...error,
                error: 'Sub Admin handle is required',
                color: 'error',
                open: true
            })
        } else if (formdata.email === '') {
            setError({
                ...error,
                error: 'Sub Admin email is required',
                color: 'error',
                open: true
            })
        } else if (validator.isEmail(formdata.email) === false) {
            setError({
                ...error,
                error: 'Enter valid email!',
                color: 'error',
                open: true
            })
        } else {
            if (formdata.id !== '') {
                const raw = {
                    'id': formdata.id,
                    'first_name': formdata.first_name,
                    'last_name': formdata.last_name,
                    'abrv': formdata.member_abrv,
                    'handle': formdata.member_handle,
                    'email': formdata.email.toLowerCase(),
                    'username': formdata.username,
                    'password': formdata.password,
                    'system_password': formdata.system_password,
                    'set_admin_as_member': formdata.set_admin_as_member
                }
                Apis.updateSubAdmin(raw).then((res) => {
                    setError({
                        ...error,
                        error: res.data.message,
                        color: 'success',
                        open: true
                    })
                    setValues({
                        ...values,
                        id: '',
                        first_name: '',
                        last_name: '',
                        member_abrv: '',
                        member_handle: '',
                        email: '',
                        username: '',
                        password: '',
                        system_password: '',
                        set_admin_as_member: false
                    })
                    setRefresh(!refresh);
                    setShowAddSubAdmin(!show_add_sub_admin)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: error.response.data.error,
                        color: 'error',
                        open: true
                    })
                })

            } else {
                if (formdata.password === '') {
                    setError({
                        ...error,
                        error: 'Sub Admin Password is required',
                        color: 'error',
                        open: true
                    })
                } else if (formdata.system_password === '') {
                    setError({
                        ...error,
                        error: 'Sub Admin System Password is required',
                        color: 'error',
                        open: true
                    })
                } else {
                    const raw = {
                        'first_name': formdata.first_name,
                        'last_name': formdata.last_name,
                        'role': formdata.role,
                        'abrv': formdata.member_abrv,
                        'handle': formdata.member_handle,
                        'email': formdata.email.toLowerCase(),
                        'username': formdata.email,
                        'password': formdata.password,
                        'system_password': formdata.system_password,
                        'set_admin_as_member': formdata.set_admin_as_member
                    }
                    Apis.addSubAdmin(raw).then((res) => {
                        setError({
                            ...error,
                            error: res.data.message,
                            color: 'success',
                            open: true
                        })
                        setValues({
                            ...values,
                            first_name: '',
                            last_name: '',
                            member_abrv: '',
                            member_handle: '',
                            email: '',
                            username: '',
                            password: '',
                            system_password: '',
                            set_admin_as_member: false
                        })
                        setRefresh(!refresh);
                        setShowAddSubAdmin(!show_add_sub_admin)

                    }).catch((error) => {
                        setError({
                            ...error,
                            error: 'Email Aleardy exist',
                            color: 'error',
                            open: true
                        })
                    })
                }

            }
        }
    }

    const handleConvertSubAdminSubmit = (formdata) => {

        if (formdata.member_id === '' && formdata.member_id === null) {
            setError({
                ...error,
                error: 'Existing Member is required',
                color: 'error',
                open: true
            })
        } else if (formdata.system_password === '') {
            setError({
                ...error,
                error: 'Sub Admin System Password is required',
                color: 'error',
                open: true
            })
        } else {
            const raw = {
                'id': formdata.member_id.value,
                'role': formdata.role,
                'password': formdata.password,
                'system_password': formdata.system_password,
                'set_admin_as_member': true
            }
            Apis.convertToSubAdmin(raw).then((res) => {
                setError({
                    ...error,
                    error: res.data.message,
                    color: 'success',
                    open: true
                })
                setValues({
                    ...values,
                    first_name: '',
                    last_name: '',
                    member_abrv: '',
                    member_handle: '',
                    email: '',
                    username: '',
                    password: '',
                    system_password: '',
                    member_id: '',
                    set_admin_as_member: false
                })
                setRefresh(!refresh);
                setShowExistingMember(!show_existing_member)

            }).catch((error) => {
                setError({
                    ...error,
                    error: 'Email Aleardy exist',
                    color: 'error',
                    open: true
                })
            })
        }
    }

    const handleEdit = (data) => {
        setValues({
            ...values,
            first_name: data.first_name,
            last_name: data.last_name,
            member_abrv: data.abrv,
            member_handle: data.handle,
            email: data.email,
            username: data.username,
            set_admin_as_member: data.set_admin_as_member,
            id: data._id
        })
        setShowAddSubAdmin(!show_add_sub_admin)
    }
    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setError({
            ...error,
            open: false
        })
        if (name === 'first_name') {
            setValues({
                ...values,
                [name]: value,
                member_handle: '@' + value.toLowerCase().replace(/ /g, '')
            })
        } else if (name === 'member_abrv') {
            setValues({
                ...values,
                [name]: value.toUpperCase().replace(/ /g, '-')

            })
        } else if (name === 'email') {
            setValues({
                ...values,
                [name]: value.toLowerCase(),
            })
        }
        else {
            setValues({
                ...values,
                [name]: value,
            })
        }

    }
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Manage Sub Admins | P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Manage Sub Admins</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Profile</li>
                                            <li className="breadcrumb-item active">Manage Sub Admins</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="row pr_tagsrow colaborate_page">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="project_addbtn d-flex justify-content-between">
                                                        <div className="row w-100">
                                                            <div className="col-lg-6"><h4 className="card-title">Sub Admins</h4></div>
                                                            <div className="col-lg-6 text-end"><button type="button" className="btn btn-primary" onClick={() => setShowExistingMember(!show_existing_member)}>Convert Existing Member<i className="fas fa-plus"></i></button></div>
                                                            {/*  <div className="col-lg-1"><button type="button" className="btn btn-primary" onClick={() => setShowAddSubAdmin(!show_add_sub_admin)}>Add<i className="fas fa-plus"></i></button></div> */}
                                                        </div>


                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-centered table-nowrap mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">First Name</th>
                                                                    <th scope="col">Last Name</th>
                                                                    <th scope="col">Abrv.</th>
                                                                    <th scope="col">Handle</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Action</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {subAdminList && subAdminList.map((elm, key) => {
                                                                    if (elm.status === 1) {
                                                                        return (
                                                                            <>
                                                                                <tr >
                                                                                    <th scope="row">{key + 1}</th>
                                                                                    <td>{elm.first_name}</td>
                                                                                    <td>{elm.last_name}</td>
                                                                                    <td>{elm.abrv}</td>
                                                                                    <td>{elm.handle}</td>
                                                                                    <td>{elm.email}</td>

                                                                                    <td>
                                                                                        <div className="d-flex addremove_btn">
                                                                                            <button type="button" className="btn btn-primary btn-sm me-1" data-bs-placement="bottom" title="Edit" onClick={() => handleEdit(elm)}><i className="fas fa-pencil-alt"></i></button>
                                                                                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Archive" onClick={() => handleDeleteSubAdmin(elm._id, 0)}><i className="fas fa-trash-alt"></i></button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <>
                                                                                <tr >
                                                                                    <th scope="row"><strike>{key + 1}</strike></th>
                                                                                    <td><strike>{elm.first_name}</strike></td>
                                                                                    <td><strike>{elm.last_name}</strike></td>
                                                                                    <td><strike>{elm.abrv}</strike></td>
                                                                                    <td><strike>{elm.handle}</strike></td>
                                                                                    <td><strike>{elm.email}</strike></td>
                                                                                    <td>
                                                                                        <div className="d-flex addremove_btn">
                                                                                            <button className="btn btn-primary btn-sm" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Restore" onClick={() => handleDeleteSubAdmin(elm._id, 1)}><i className="fas fa-trash-restore"></i></button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>

            {/* Add Edit Sub Admin Modal */}
            {/*  <Modal show={show_add_sub_admin} aria-labelledby="contained-modal-title-vcenter" size="lg" onHide={() => setShowAddSubAdmin(!show_add_sub_admin)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{(values.id !== '') ? 'Update Sub Admin' : 'Add Sub Admin'} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">First Name</label>
                                <input type="text" className="form-control" id="recipient-name" name='first_name' value={values.first_name} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Last Name</label>
                                <input type="text" className="form-control" id="recipient-name" name='last_name' value={values.last_name} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Abrv.</label>
                                <input type="text" className="form-control" id="recipient-name" name='member_abrv' value={values.member_abrv} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Handle</label>
                                <input type="text" className="form-control" id="recipient-name" name='member_handle' value={values.member_handle} readOnly />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Email</label>
                                <input type="email" className="form-control" id="recipient-name" name='email' value={values.email} onChange={handleChange} disabled={(values.id !== '') ? true : false} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="password" className="col-form-label">Login password</label>
                                <input type="text" className="form-control" id="password" name='password' value={values.password} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="system_password" className="col-form-label">System password</label>
                                <input type="text" className="form-control" id="system_password" name='system_password' value={values.system_password} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mt-2">
                            <div className="mb-3 form-check">
                                <input className="form-check-input" type="checkbox" name="set_admin_as_member" onChange={() => setValues({
                                    ...values,
                                    ['set_admin_as_member']: !values.set_admin_as_member
                                })} id="set_admin_as_member" checked={values.set_admin_as_member} />
                                <label htmlFor="set_admin_as_member" className="form-check-label">Show in Member</label>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div >
                        <button type="button" className="btn btn-primary" data-bs-dismiss={(values.email !== '') ? "modal" : ""} onClick={() => handleSubAdminSubmit(values)}>{(values.id !== '') ? 'Update' : 'Add'}</button>
                    </div>
                </Modal.Footer>

            </Modal> */}
            {/* Add Edit Sub Admin Modal */}
            <Modal show={show_existing_member} aria-labelledby="contained-modal-title-vcenter" size="lg" onHide={() => setShowExistingMember(!show_existing_member)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Convert Existing Members </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 mt-2">
                            <div className="mb-3">
                                <label htmlFor="password" className="col-form-label">Existing Member</label>
                                <ReactSelect
                                    options={member_List}
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    isClearable
                                    value={values.member_id}
                                    onChange={(selected) => setValues({
                                        ...values,
                                        ['member_id']: selected

                                    })}
                                />
                            </div>
                        </div>
                        {/* <div className="col-lg-4 mt-2">
                            <div className="mb-3">
                                <label htmlFor="password" className="col-form-label">Login password</label>
                                <input type="text" className="form-control" id="password" name='password' value={values.password} onChange={handleChange} />
                            </div>
                        </div> */}
                        <div className="col-lg-6 mt-2">
                            <div className="mb-3">
                                <label htmlFor="system_password" className="col-form-label">System password</label>
                                <input type="text" className="form-control" id="system_password" name='system_password' value={values.system_password} onChange={handleChange} />
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div >
                        <button type="button" className="btn btn-primary" onClick={() => handleConvertSubAdminSubmit(values)}>Convert</button>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default AddSubAdmin