
/**
 * Application configuration
 */
import { DateHelper, StringHelper, TaskModel } from '@bryntum/gantt';
import './helper/GanttToolbar';
import '../../../CAP/helper/CapGanttComponets/helpers/GanttToolbar'
import DemoButton from './helper/DemoButton'
import Apis from '../../../../../api'

class MyTask extends TaskModel {
    static get fields() {
        return [
            'hoursWorked'
        ];
    }

    get workedHoursByDay() {
        const
            me = this,

            { startDate, endDate, isParent, duration } = me;


        // With Gantt using early rendering we can get here before dates are normalized, if so bail out to not cache
        // invalid values below
        if (!startDate || !endDate || !duration) {
            return null;
        }

        if (!isParent && me._workedHoursByDay?.duration === duration && me._workedHoursByDay.startDateMs === startDate.getTime()) {
            return me._workedHoursByDay;
        }

        const
            durationInDays = DateHelper.getDurationInUnit(startDate, endDate, 'd', false),

            workedHoursByDay = Array(durationInDays || 0).fill(0),
            calendar = me.project.calendar,
            hoursWorked = me.hoursWorked || [];


        let index = 0;

        // Rollup values from parent task's immediate children
        for (let i = 0; i < durationInDays; i++) {
            const
                intervalStart = DateHelper.add(startDate, i, 'd'),
                intervalEnd = DateHelper.add(intervalStart, 1, 'd');

            if (calendar.isWorkingTime(intervalStart, intervalEnd)) {
                if (isParent) {
                    workedHoursByDay[i] = this.children.reduce((total, child) => {

                        if (DateHelper.intersectSpans(child.startDate, child.endDate, intervalStart, intervalEnd)) {
                            const startDiff = DateHelper.getDurationInUnit(startDate, child.startDate, 'd');
                            return total += child.workedHoursByDay[i - startDiff];
                        }
                        else {
                            return total;
                        }
                    }, 0);
                }
                else {
                    workedHoursByDay[i] = hoursWorked[index];

                }
                index++;
            }
        }

        // Cache by start + duration
        workedHoursByDay.startDateMs = startDate.getTime();
        workedHoursByDay.duration = duration;

        me._workedHoursByDay = workedHoursByDay;

        return workedHoursByDay;
    }
}

export const ganttConfig = {
    tbar: { type: 'gantttoolbarss' },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    subGridConfigs: {
        locked: {
            flex: 6
        },
        normal: {
            flex: 3
        }
    },
    columnLines: true,
    viewPreset: {
        base: 'weekAndDay',
        tickWidth: 50,
        tickHeight: 50,
        shiftIncrement: 1,
        shiftUnit: 'day',

        timeResolution: {
            unit: 'day',
            increment: 1
        },
        headers: [
            {
                unit: 'month',
                dateFormat: 'MMM YYYY',
                align: 'center'
            },
            {
                unit: 'week',
                renderer: (startDate, endDate) => `${DateHelper.format(startDate, 'DD MMM')} - ${DateHelper.format(endDate, 'DD MMM')} (Week ${DateHelper.format(startDate, 'WW')})`
            },
            {
                unit: 'day',
                dateFormat: 'ddd, D'
            }
        ],

    },
    timeAxisHeaderMenuFeature: {
        items: {
            dateRange: false,
            nameFilter: false,
            currentTimeLine: false,
            eventsFilter: false,
        }
    },
    timeRangesFeature: {
        showHeaderElements: false,
        showCurrentTimeLine: true
    },
    columns: [
        {
            type: 'name',
            text: 'Task name',
            editor: false,
            width: 300,
            sortable: false,
            renderer: ({ record, grid, grid: { extraData } }) => {
                if (record.type === 'Milestone') {
                    return <>
                        <DemoButton
                            text={'EditMeilstone'}
                            name={record.name}
                            onClick={() => extraData.edit({ record, grid })}
                        />
                    </>
                } else if (record.type === 'Task') {

                    if (record.isCompletedSuccessfully === true) {
                        return record.name
                    } else if (record.status_code[0].value === 4 || record.status_code[0].value === 6 || record.status_code[0].value === 6) {
                        return record.name
                    } else {
                        return <>
                            <DemoButton
                                text={'EditTask'}
                                name={record.name}
                                onClick={() => extraData.edit({ record, grid })}
                            />
                        </>
                    }

                } else {
                    return record.name
                }
            }
        },
        { type: 'duration', width: 130, editor: false, sortable: false },

        {
            text: 'Location', width: 130, editor: false, sortable: false,
            renderer: ({ record }) => {
                return <>{record.location_name}</>

            }
        },
        {
            editor: false,
            text: 'Status',
            width: 130,
            sortable: false,
            renderer: ({ record }) =>
                record.type === 'Task' ? record.status_code[0].label : record.type === 'Milestone' ? record.status_code[0].label : null
        },
        {
            text: "Team", width: 130, editor: false, sortable: false, filterable: false,
            renderer: ({ record }) => {
                return <>{record.team.label} </>

            }
        },
        {
            text: "Member", width: 130, editor: false, sortable: false, filterable: false,
            renderer: ({ record }) => {
                return <>{record.member.label} </>

            }
        },
        {
            text: 'Action',
            editor: false,
            width: 130,
            sortable: false,
            renderer: ({ record, grid: { extraData } }) =>
                record.type === 'Milestone' ? (
                    <>
                        <DemoButton
                            text={'AddTask'}
                            onClick={() => extraData.add({ record })}
                        />
                    </>
                ) : null

        }
    ],
    
    rollupsFeature: {
        disabled: true
    },
    baselinesFeature: {
        disabled: true
    },
    progressLineFeature: {
        disabled: true
    },
    dependencyEditFeature: {
        editorConfig: {
            title: 'Do you want to delete dependency?',
            items: {
                // Custom label for the type field
                typeField: {
                    hidden: true
                },
                lagField: {
                    hidden: true
                },
                activeField: {
                    hidden: true
                }
            },
            bbar: {
                items: {
                    saveButton: {
                        hidden: true
                    },
                    deleteButton: {
                        cls: 'b-raised',
                        icon: 'b-fa-trash b-fa-fw',
                        color: 'b-red',
                    }
                }
            }
        }
    },
    taskTooltipFeature: {
        disabled: true
    },
    headerMenuFeature: {
        disabled: true
    },

    taskEditFeature: {
        disabled: true
    },
    dependenciesFeature: {
        allowCreate: true,
    },
    projectLinesFeature: {
        disabled: true
    },

    taskDragFeature: {
        validatorFn(draggedTaskRecords, newStartDate) {
            let valid = true
            const status = draggedTaskRecords[0].status_code[0].value

            if (status === 4 || status === 5 || status === 6) {
                valid = false
            }
            return valid

        }
    },
    taskResizeFeature: {
        validatorFn(record) {
            let valid = true
            const status = record.taskRecord.status_code[0].value
            if (status === 4 || status === 5 || status === 6) {
                valid = false
            }
            return valid

        }
    },
    taskMenuFeature: {
        disabled: true
    },
    gridMenuFeature: {
        disabled: true
    },
    percentBarFeature: false,
    taskRenderer({ taskRecord }) {

        const getBusinessDatesCount = (startDate, endDate) => {
            let count = 0;
            let curDate = startDate;
            while (curDate < endDate) {
                const dayOfWeek = new Date(curDate).getDay();
                const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
                if (!isWeekend) {
                    count++;
                }
                curDate = curDate + 24 * 60 * 60 * 1000
            }

            return count;
        }
        let difference = 0
        if (!taskRecord.isMilestone) {
            if (taskRecord.type === 'Task') {
                const complition_date = taskRecord.taskrefdate
                const endDate = new Date(taskRecord.endDate).getTime()
                if (complition_date === 0) {
                    return [
                        {
                            tag: 'div',
                            class: 'taskName',
                            html: StringHelper.encodeHtml(taskRecord.name)
                        }
                    ]
                }
                if (complition_date < endDate) {
                    difference = getBusinessDatesCount(complition_date, endDate)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: "-" + difference,
                                role: 'presentation'
                            }
                        ]
                    }

                } else {
                    difference = getBusinessDatesCount(endDate, complition_date)
                    if (difference <= 0) {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            }
                        ]
                    } else {
                        return [
                            {
                                tag: 'div',
                                class: 'taskName',
                                html: StringHelper.encodeHtml(taskRecord.name)
                            },
                            {
                                tag: 'div',
                                class: 'gantt_task_delta_marker',
                                text: difference,
                                role: 'presentation'
                            }
                        ]
                    }

                }

            } else {
                return [
                    {
                        tag: 'div',
                        class: 'taskName',
                        html: StringHelper.encodeHtml(taskRecord.name)
                    }
                ]
            }

        } else {
            const complition_date = taskRecord.taskrefdate
            const endDate = new Date(taskRecord.endDate).getTime()
            if (complition_date < endDate) {
                difference = getBusinessDatesCount(complition_date, endDate)
                if (difference <= 0) {
                    return []
                } else {
                    return [
                        {
                            tag: 'div',
                            class: 'gantt_task_delta_marker_milestone',
                            text: "-" + difference,
                            role: 'presentation'
                        }
                    ]
                }

            } else {
                difference = getBusinessDatesCount(endDate, complition_date)
                if (difference <= 0) {
                    return []
                } else {
                    return [
                        {
                            tag: 'div',
                            class: 'gantt_task_delta_marker_milestone',
                            text: difference,
                            role: 'presentation'
                        }
                    ]
                }

            }
        }
    },

    rowReorderFeature: {
        listeners: {
            gridRowDrop: ({ context }) => {
                if (context.valid === true) {
                    const user = JSON.parse(localStorage.getItem('user-info'))
                    let datas = context.parent.children
                    let raws = {
                        id: datas.map((elm) => {
                            return elm.id
                        }),
                        parentIndex: datas.map((elm) => {
                            return elm.parentIndex
                        }),
                        user_id: user.id,
                        timeStamp: new Date().getTime()
                    }
                    Apis.postionTask(raws).then((res) => {
                        return true
                    }).catch((error) => {
                        return false
                    })
                }
            },
            gridRowBeforeDropFinalize: async ({ context }) => {
                const status = context.records[0].status_code[0].value
                if (status === 4 || status === 5 || status === 6) {
                    return false
                } else {
                    return true
                }

            }
        }
    },
    listeners: {
        beforeDependencyDelete({ dependencyRecord }) {
            const fromstatus = dependencyRecord.fromTask.status_code[0].value
            const tostatus = dependencyRecord.toTask.status_code[0].value
            dependencyRecord.fromTask.taskrefdate = 0
            if (fromstatus === 4 || fromstatus === 5 || fromstatus === 6 || tostatus === 4 || tostatus === 5 || tostatus === 6) {
                return false
            }
            const child_id = dependencyRecord.toTask.parentIndex
            const child_arry = dependencyRecord.toTask._parent.children
            child_arry[child_id].manuallyScheduled = true


            const raw = {
                id: dependencyRecord.id,
                toTask: dependencyRecord.toTask.id,
                fromTask: dependencyRecord.fromTask.id

            }
            Apis.deleteLink(raw).then((res) => {

                if (res.data.success === true) {
                    if (res.data.status === true) {
                        if (child_arry[child_id].status_code[0].label === 'Constrained') {
                            child_arry[child_id].status_code = [{
                                value: 1,
                                label: 'Released'
                            }]
                        }
                        return true
                    } else {
                        return true
                    }
                }
            }).catch((error) => {
                return false
            })

        },
        dependencyCreateDrop({ source, target, dependency }) {

            source.taskrefdate = new Date(new Date(source.endDate).getTime())
            const raw = {
                fromTask: source.id,
                toTask: target.id,
                complition_date: new Date(source.endDate).getTime()
            }
            Apis.createLink(raw).then((res) => {
                const link = res.data.link
                const user = JSON.parse(localStorage.getItem('user-info'))
                dependency.id = link._id
                let datas = source._parent.children
                let raw = {
                    id: datas.map((elm) => {
                        return elm.id
                    }),
                    dates: datas.map((elm) => {
                        let newStartDate = elm._data.startDate
                        return new Date(newStartDate).getTime()
                    }),
                    endDate: datas.map((elm) => {
                        let newEndDate = elm._data.endDate
                        return new Date(newEndDate).getTime()
                    }),
                    parentIndex: datas.map((elm) => {
                        return elm.parentIndex
                    }),
                    user_id: user.id,
                    timeStamp: new Date().getTime()
                }
                Apis.bulkTaskDrag(raw).then((res) => {
                    return true
                }).catch((error) => {
                    return false
                })
            }).catch((error) => {
                return false
            })
        },

        beforeDependencyCreateFinalize({ source, target, fromSide, toSide }) {
            if (fromSide === 'end' && toSide === 'start') {
                if (target.status_code[0].label === 'Released') {
                    target.status_code = [{
                        value: 3,
                        label: 'Constrained'
                    }]
                    target.manuallyScheduled = false
                }
                return true

            } else {
                return false
            }
        },
        taskResizeEnd({ changed, taskRecord }) {

            if (taskRecord.status_code[0].value === 5) {
                changed = false
                return false
            }
            const user = JSON.parse(localStorage.getItem('user-info'))

            const raw = {
                name: taskRecord.name,
                duration: taskRecord.duration,
                id: taskRecord.id,
                endDate: new Date(taskRecord._data.earlyEndDate).getTime(),
                user_id: user.id,
                timeStamp: new Date().getTime()
            }
            Apis.resizeTask(raw).then((res) => {
                return true
            }).catch((error) => {
                return false
            })
        },

    }
};

export const projectConfig = {
    calendar: 'general',
    startDate: DateHelper.add(DateHelper.clearTime(new Date()), -200, 'day'),
    endDate: DateHelper.add(DateHelper.clearTime(new Date()), +200, 'day'),
    hoursPerDay: 24,
    daysPerWeek: 5,
    daysPerMonth: 20,
    autoLoad: true,
    monitorResize: true,
    stm: {
        autoRecord: true
    },
    taskModelClass: MyTask,

};
export const resourceProjectConfig = {
    calendar: 'general',
    startDate: DateHelper.add(DateHelper.clearTime(new Date()), -200, 'day'),
    endDate: DateHelper.add(DateHelper.clearTime(new Date()), +200, 'day'),
    hoursPerDay: 24,
    daysPerWeek: 5,
    daysPerMonth: 20,
    taskModelClass: MyTask,
};
export const ganttResourcesConfig = {
    tbar: { type: 'gantttoolbars' },
    viewPreset: {
        base: 'weekAndDay',

        tickWidth: 50,
        tickHeight: 50,
        shiftIncrement: 1,
        shiftUnit: 'day',
        timeResolution: {
            unit: 'day',
            increment: 1
        },
        headers: [
            {
                unit: 'month',
                dateFormat: 'MMM YYYY',
                align: 'center'
            },
            {
                unit: 'week',
                renderer: (startDate, endDate) => `${DateHelper.format(startDate, 'DD MMM')} - ${DateHelper.format(endDate, 'DD MMM')} (Week ${DateHelper.format(startDate, 'WW')})`
            },
            {
                unit: 'day',
                dateFormat: 'ddd, D'
            }
        ],

    },
    visibleDate: {
        date: new Date(),
        block: 'center'
    },
    columns: [{ type: 'name', field: 'name', width: 250, sortable: false, editor: false }],
    timeRangesFeature: {
        showHeaderElements: false,
        showCurrentTimeLine: true,
    },
    timeAxisHeaderMenuFeature: {
        items: {
            dateRange: false,
            nameFilter: false,
            currentTimeLine: false,
            eventsFilter: false,
        }
    },
    taskDragFeature: {
        disabled: true
    },
    rollupsFeature: {
        disabled: true
    },
    baselinesFeature: {
        disabled: true
    },
    progressLineFeature: {
        disabled: true
    },
    projectLinesFeature: {
        disabled: true
    },
    percentBarFeature: false,
    columnLines: true,
    taskTooltipFeature: {
        disabled: true
    },
    headerMenuFeature: {
        disabled: false
    },
    taskEditFeature: {
        disabled: true
    },
    dependenciesFeature: {
        allowCreate: false,
    },
    taskMenuFeature: {
        disabled: true
    },

    taskResizeFeature: false,
    rowReorderFeature: false,
    gridMenuFeature: {
        disabled: true
    },
    subGridConfigs: {
        locked: {
            flex: 6
        },
        normal: {
            flex: 3
        }
    },
    taskRenderer({ taskRecord }) {
        if (!taskRecord.isMilestone) {
            const workedHoursByDay = taskRecord.workedHoursByDay || [];
            return [
                {
                    tag: 'div',
                    class: 'hoursWorked',
                    children: workedHoursByDay.map(workedHours => {
                        if (workedHours !== 0) {
                            return {
                                class: 'presentation',
                                text: workedHours,
                                style: {
                                    backgroundColor: taskRecord.eventColor === '#ffffff00' ? '#51c185' : taskRecord.eventColor
                                },
                            };
                        } else {
                            return {
                                text: ''
                            }
                        }

                    })
                }
            ];
        }
    }


};





