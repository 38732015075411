import React from 'react'
import Header from '../../../Layout/Header'
import Sidebar from '../../../Layout/Sidebar'
import Footer from '../../../Layout/Footer'
import RequestByTeam from './componets/RequestByTeam'
import RequestBySupplier from './componets/RequestBySupplier'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Row, Col } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import Apis from '../../../../../api'

function MaterialReports() {
    const [key, setKey] = React.useState('home');

    const [taskData, setTaskDatas] = React.useState([])
    const [materialList, setMaterialsList] = React.useState([])
    const [suppliersList, setSupplierList] = React.useState([])
    const [teamList, setTeam] = React.useState([])
    const [attributeList, setAttributeList] = React.useState([])

    React.useEffect(() => {
        const filter = [{ type: 'Task' },
        { delete_status: false }]
        const raw = {
            "filter": filter
        }
        Apis.taskfilter(raw).then((res) => {
            setTaskDatas(res.data.tasks)
        }).catch((error) => {
            console.log(error.response.data)
        })
    }, [])
    React.useEffect(() => {
        Apis.getMaterial().then((res) => {
            setMaterialsList(res.data.data.materials)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getSuppliers().then((res) => {
            setSupplierList(res.data.data.suppliers)
        }).catch((error) => {
            console.log(error)
        })
        Apis.getTeam().then((res) => {
            setTeam(res.data.data.team)
        }).catch(error => {
            console.log(error.response.data)
        })
        Apis.getmaterialAttribute().then((res) => {
            setAttributeList(res.data.data.attribute)
        }).catch((error) => {
            console.log(error)
        })
    }, [taskData])



    return (
        <>
            <div id="layout-wrapper">
                <Header title='Material Reports | P2IC'></Header>
                <Sidebar title='' show='AnalyticsReports'></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Analytics & Reports</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Analytics & Reports</li>
                                            <li className="breadcrumb-item active">Material Reports</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <Row className='pr_tagsrow mb-3'>
                                <Col className='col-lg-12'>
                                    <Row>
                                        <Col className="col-lg-12">
                                            <Card>
                                                <Card.Body >
                                                    <Tabs
                                                        activeKey={key}
                                                        onSelect={(k) => setKey(k)}
                                                        className="mb-3 fs-4 justify-content-center"
                                                    >
                                                        <Tab eventKey="home" title="Material Request By Team">
                                                            <RequestByTeam
                                                                taskData={taskData}
                                                                materialList={materialList}
                                                                suppliersList={suppliersList}
                                                                teamList={teamList}
                                                                attributeList={attributeList}
                                                            />
                                                        </Tab>
                                                        <Tab eventKey="profile" title="Material Request By Supplier">
                                                            <RequestBySupplier 
                                                             materialList={materialList}
                                                             suppliersList={suppliersList}                                                            
                                                             attributeList={attributeList}
                                                             />
                                                        </Tab>

                                                    </Tabs>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="row">
                                <Footer></Footer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaterialReports
