import React, { forwardRef } from 'react'
import { Label, Input } from 'reactstrap'
import styles from '../../../Analytics/helpers/styles.module.scss'
import { default as ReactSelect } from "react-select"
import DatePicker from 'react-datepicker'

const status_codes = [
    {
        value: true,
        label: 'Ordered'
    }, {
        value: false,
        label: 'Not yet Ordered'
    }
]
const type_code = [
    {
        value: 1,
        label: 'All'
    }, {
        value: 2,
        label: 'Material'
    }, {
        value: 3,
        label: 'Equipment'
    }
]

const CustomDatePickerInput = forwardRef(({ value, ...props }, ref) => {
    const formattedValue = value
        ? new Date(value).getDate() + '/' + parseInt(new Date(value).getMonth() + 1) + '/' + new Date(value).getFullYear()
        : ''
    
    return <Input type="text" {...props} value={formattedValue} ref={ref} />
})


const Toolbar = ({ teams = [], state = {}, onChange }) => {
    var teamsValue = null
    var statusValue = null
    var typeValue = null
    if (state['team'] !== '') {
        teamsValue = teams.find((i) => i.value === state['team'])
    }
    if (state['order_status'] !== '') {
        statusValue = status_codes.find((i) => i.value === state['order_status'])
    }
    if (state['type_code'] !== '') {
        typeValue = type_code.find((i) => i.value === state['type_code'])
    }
   

    const onTeamChange = (option, params) => {
        if (params.name === 'type_code') {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? type_code[0].value : option.value,
            })
        } else {
            onChange({
                name: params.name,
                value: params.action === 'clear' ? '' : option.value,
            })
        }

    }

    const onFromDateChange = (date) => {
        if (typeof onChange !== 'function') return
        console.log(date)
        onChange({
            name: 'date',
            value: date,
        })
    }

    return (
        <>
            <div className='container-fluid'>
                <div className="row justify-content-between">
                    <div className="col-lg-3">
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Team
                        </Label>
                        <ReactSelect
                            options={[...teams]}
                            value={teamsValue}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            name="team"
                            onChange={onTeamChange}
                            isClearable
                        />
                    </div>
                    <div className="col-lg-3">
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Status
                        </Label>
                        <ReactSelect
                            options={status_codes}
                            value={statusValue}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            name="order_status"
                            onChange={onTeamChange}
                            isClearable
                        />
                    </div>
                    <div className="col-lg-3">
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Type
                        </Label>
                        <ReactSelect
                            options={type_code}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            name="type_code"
                            onChange={onTeamChange}
                            value={typeValue}
                            isClearable

                        />


                    </div>
                    <div className="col-lg-3">
                        <Label for="analytics-filter-panel-teams" className={styles.ganttToolbarSelectLabel}>
                            Date
                        </Label>
                        <DatePicker
                            selected={state['date']}
                            onChange={onFromDateChange}
                            selectsStart
                            name='date'
                            customInput={<CustomDatePickerInput />}
                            showPopperArrow={false}

                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Toolbar