import React, { createRef } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import keyBy from 'lodash/keyBy'
import Apis from '../../../../../../api';
import RequestByTeamToolbar from './Toolbar/RequestByTeamToolbar';
import { Button } from 'reactstrap'
import Pdf from "react-to-pdf";

const XAxisPading = { left: 60, right: 60 }
const XAxisTick = { fontSize: '0.8em' }
const labelFormatter = (value) => new Date(value).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',

})

const dateFormatter = (value) =>

    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',

    })

const findArryIndex = (arr, list) => {

    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];

        if (el.team === list.team && el.material === list.material && el.date === list.date && el.supplier === list.supplier && el.uom === list.uom) {
            return i
        }
    }
    return -1

}

const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [6, 12]

};

const RequestByTeam = ({ taskData, materialList, suppliersList, teamList, attributeList }) => {

    const ref = createRef();

    const [taskmaterialList, setTaskMaterialList] = React.useState([])
    const [requestByTeam, SetRequestByTeam] = React.useState([])
    const [graphData, setGraphData] = React.useState([])
    const [toolbarState, setToolbarState] = React.useState({
        team: '',
        supplier: '',
        material: ''
    })
    const taskByID = keyBy(taskData, '_id')
    const materialById = keyBy(materialList, '_id')
    const supplierById = keyBy(suppliersList, '_id')
    const teamByID = keyBy(teamList, '_id')
    const attributeById = keyBy(attributeList, '_id')



    React.useEffect(() => {
        const filter = [{ status: true }]
        let demo
        if (toolbarState.from_date) {
            demo = { timeStamp: { $gte: new Date(toolbarState.from_date).getTime() } }
            filter.push(demo)
        }
        if (toolbarState.to_date) {
            demo = { timeStamp: { $lte: new Date(toolbarState.to_date).getTime() } }
            filter.push(demo)
        }
        const raw = {
            "filter": filter
        }
        Apis.getTaskMaterialByFilter(raw).then((res) => {
            setTaskMaterialList(res.data.data.taskmaterials)
        }).catch((error) => {
            console.log(error)
        })
    }, [taskData, toolbarState])

    React.useEffect(() => {

        const listed = [];
        taskmaterialList.map((list, key) => {
            var demo = {
                id: list._id,
                team: taskByID[list.task_id]?.team.value,
                material: list.material,
                supplier: materialById[list.material]?.supplier,
                date: new Date(list.timeStamp).setHours(0, 0, 0, 0),
                quantity: list.quantity,
                uom: materialById[list.material]?.uom,
                sub_quanity: [list.quantity]

            }
            var index = findArryIndex(listed, demo);
            if (index === -1) {
                listed.push(demo)
            } else {
                listed[index].quantity = parseInt(listed[index].quantity) + parseInt(list.quantity)
                listed[index].sub_quanity.push(list.quantity)

            }

            return listed
        })
        SetRequestByTeam(listed)

    }, [taskmaterialList])
    React.useEffect(() => {
        const list = []
        if (requestByTeam.length !== 0) {
            requestByTeam.map((elm) => {
                if (toolbarState.supplier !== '' || toolbarState.material !== '') {
                    if (toolbarState.supplier === elm.supplier && toolbarState.material === '') {
                        var demo = {
                            startDate: elm.date,
                            [elm.team]: elm.quantity,
                            material: elm.material,
                            supplier: elm.supplier,
                            uom: elm.uom,
                            quantity: elm.sub_quanity
                        }
                        list.push(demo)
                    } else if (toolbarState.supplier === elm.supplier && toolbarState.material === elm.material) {
                        var demo = {
                            startDate: elm.date,
                            [elm.team]: elm.quantity,
                            material: elm.material,
                            supplier: elm.supplier,
                            uom: elm.uom,
                            quantity: elm.sub_quanity
                        }
                        list.push(demo)
                    } else if (toolbarState.supplier === '' && toolbarState.material === elm.material) {
                        var demo = {
                            startDate: elm.date,
                            [elm.team]: elm.quantity,
                            material: elm.material,
                            supplier: elm.supplier,
                            uom: elm.uom,
                            quantity: elm.sub_quanity
                        }
                        list.push(demo)
                    }
                } else {
                    var demo = {
                        startDate: elm.date,
                        [elm.team]: elm.quantity,
                        material: elm.material,
                        supplier: elm.supplier,
                        uom: elm.uom,
                        quantity: elm.sub_quanity
                    }
                    list.push(demo)
                }


                return list
            })
        }
        setGraphData(list)
    }, [requestByTeam, toolbarState.supplier, toolbarState.material])


    const onToolbarStateChange = ({ name, value }) => {
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }
    const onResetToolbarState = () => setToolbarState({
        team: '',
        supplier: '',
        material: ''
    })


    const CustomTooltip = ({ payload, label, active }) => {
        if (payload) {
            var data = payload[0]
            var html = '';


            if (data) {
                if (active) {
                    var sum_quantity = data.payload.quantity
                    var length = sum_quantity.length
                    length = length - 1
                    sum_quantity.map((elm, key) => {
                        html += elm;
                        if (key !== length) {
                            html += "+";
                        }

                    })
                    return (
                        <div className="custom-tooltip ">
                            <div className="tooltip-body">
                                <p className="label mb-1">{labelFormatter(label)}</p>
                                <p className="mb-0" style={{ color: data.fill }}>{teamByID[data.name]?.name + ', ' + supplierById[data.payload.supplier]?.company_name}</p>
                                <p className="mb-0" style={{ color: data.fill }}>{materialById[data.payload.material]?.material_name + ', ' + attributeById[data.payload.uom]?.tag_name} <span style={{ color: '#000' }}>{': ' + payload[0].value}</span></p>
                                <p>{'(' + html + ')'}</p>
                            </div>
                        </div>
                    );
                }
            }
        }

    }

    const wrapperRef = React.useRef()
    return (
        <>
            <Row>
                <Col className='col-lg-12 mb-5'>
                    <Row>
                        <Col className='col-lg-9'>
                            <RequestByTeamToolbar
                                teams={teamList.map((i) => ({ value: i._id, label: i.name }))}
                                suppliers={suppliersList.map((i) => ({ value: i._id, label: i.company_name }))}
                                materials={materialList.map((i) => ({ value: i._id, label: i.material_name }))}
                                onChange={onToolbarStateChange}
                                state={toolbarState}
                                showTeam={true}
                            />
                        </Col>
                        <Col className='col-lg-3 mt-3'>
                            <div className='container-fluid'>
                                <Row className=" align-self-end justify-content-end">
                                    <Col className="col-lg-6 d-flex justify-content-end ">
                                        <Pdf targetRef={ref} filename={"Material-Request-By-Team-" + new Date().toDateString() + ".pdf"} options={options} x={.6} y={.6} scale={0.7}  >
                                            {({ toPdf }) => <Button className='btn btn-success' onClick={toPdf}>Generate Pdf</Button>}
                                        </Pdf>
                                    </Col>
                                    <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                        <Button
                                            color="danger"
                                            className="btn-primary btn waves-effect waves-light"
                                            onClick={onResetToolbarState}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <div ref={ref} >
                    <Col className='col-lg-12 text-center '>
                        <Card>
                            <Card.Body>
                                <div style={{ height: '400px' }}>
                                    <ResponsiveContainer width="100%" height="100%" ref={wrapperRef}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={graphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid stroke='#f8f8f8' vertical={false} />
                                            <XAxis
                                                dataKey="startDate"
                                                domain={["dataMin", "dataMax"]}
                                                tickFormatter={dateFormatter}
                                                axisLine={true}
                                                tick={XAxisTick}
                                                height={70}
                                                fill="#5b626b"
                                                tickLine={false}
                                                padding={XAxisPading}
                                                interval="preserveStartEnd"
                                            />
                                            <YAxis
                                                tickCount={10}
                                                axisLine={false}
                                                tickLine={false}
                                                type="number"
                                            />
                                            <Tooltip
                                                content={<CustomTooltip />}
                                            />
                                            <Legend

                                                formatter={(value) => teamByID[value]?.name ? teamByID[value]?.name : "Trend Line"}
                                            />
                                            {teamList && teamList.map((t, key) => {
                                                if (toolbarState.team !== '') {
                                                    if (toolbarState.team === t._id) {
                                                        return (
                                                            <Bar
                                                                key={t._id}
                                                                dataKey={t._id}
                                                                fill={t.color}
                                                                stackId="a" />
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Bar
                                                            key={t._id}
                                                            dataKey={t._id}
                                                            fill={t.color}
                                                            stackId="a" />
                                                    )
                                                }

                                            })}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>

            </Row>
        </>
    )
}

export default RequestByTeam