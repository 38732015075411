import React from 'react'
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

function Dashboard() {
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Dashboard | P2IC'></Header>
                <Sidebar title='dashboard' show=''></Sidebar>

                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">

                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Dashboard</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Welcome to the Project Dashboard</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>                           

                            <div className="row mb-3">
                                <div className="col-lg-5 einsteen_image">
                                    <img src="./assets/images/dashboard-einsteineimage.png" alt='' />
                                </div>
                                <div className="col-lg-7">
                                    <h1 className="einsteine_text">“If you always do what you always did, you will always get what you always got.”</h1>
                                </div>
                            </div>

                            <div className="row text_area">

                                <div className="col-lg-12">
                                    <h4 className="textarea_heading">Project Production Management</h4>
                                    <p>Project Performance Improvement Centre (P2IC) has been built based on the needs and demands of our clients.<br />It is a web based enabling system that allows owners and the engaged delivery teams to integrate, collaborate, and search for ways to eliminate waste in processes.<br />We have designed P2IC to remove variation and help create continuous workflow to drive significant improvement in predictability of delivering projects on time, safely, and within budget.</p>
                                    <h4 className="textarea_heading">3 Core Practices</h4>
                                    <h6 className="textarea_subheading">1. Early Stakeholder Involvement</h6>
                                    <p>It’s no secret that stakeholder involvement is critical to project success. However, collaboration
                                        between construction crews and contractors often takes place later in the process than is ideal. Lean Construction emphasizes
                                        collaboration between these stakeholders from the beginning. Contractors are no longer selected on the basis of cost alone.
                                        Instead, the input they can provide during the combined design-build stages drives the selection. <br />This process helps to
                                        eliminate conflicts from the beginning, reducing total project waste and requests for rework. Despite the importance of
                                        this process, it’s still relatively under the radar for contractors.</p>
                                    <h6 className="textarea_subheading">2. Pull Planning</h6>
                                    <p>Most projects in the construction industry follow a linear planning process. It begins in design and preconstruction,
                                        and time frames are only adjusted as issues occur during the process. Despite this planning style’s popularity, it can lead
                                        to many issues, including roadblocks, weak profit margins, employee burnout, and poor productivity rates. <br />Pull planning
                                        addresses some of the common issues experienced with linear planning. First, the team defines the final deadline. Then, they
                                        work backward to determine deadlines for key milestones, project phases, and handoffs. Teams can prioritize the most critical
                                        tasks and determine any dependencies between tasks. Weekly planning sessions and daily meetings are used to ensure the project
                                        plan stays on track.</p>
                                    <h6 className="textarea_subheading">3. Weekly/Daily Work Planning and Percent Plan Complete</h6>
                                    <p>Construction companies need an effective way to keep track of deadlines and pinpoint any risks for going over timeline and
                                        budget. After all, 70% of construction projects are over time and budget. The percent plan complete (PPC) technique and weekly
                                        work planning can help. PPC tracks the total percentage of assignments that have reached 100% completion. The metric is calculated
                                        by dividing the number of activities completed by the total number of activities planned. <br />Weekly/Daily work planning sessions
                                        integrate nicely with PPC to review progress, risks, and next steps. Without the planning sessions, there is a greater risk of
                                        delays, wasted resources and missed deadlines. To achieve the best results, include stakeholders from all levels. That way, each
                                        team will receive the information a nd guidance they need to maintain agility and adapt quickly to minimize interruptions.</p>
                                </div>

                            </div>

                        </div>

                    </div>

                    <Footer></Footer>
                </div>

            </div>
        </>
    )
}

export default Dashboard
