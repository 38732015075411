import React, { createRef } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import keyBy from 'lodash/keyBy'
import Apis from '../../../../../../api';
import RequestByTeamToolbar from './Toolbar/RequestByTeamToolbar';
import { Button } from 'reactstrap'
import Pdf from "react-to-pdf";

const findArryIndex = (arr, list) => {

    for (var i = 0; i < arr.length; i++) {
        var el = arr[i];

        if (el.equipment === list.equipment && el.date === list.date && el.supplier === list.supplier) {
            return i
        }
    }
    return -1

}

const dateFormatter = (value) =>

    new Date(value).toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: '2-digit',

    })

const XAxisTick = { fontSize: '0.8em' }
const XAxisPading = { left: 60, right: 60 }
const LegendWrapperStyle = { lineHeight: 4 }

const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [6, 12]

};

const RequestBySupplier = ({ equipmentList, suppliersList }) => {
    const ref = createRef();
    const wrapperRef = React.useRef()
    const [taskEquipmentLists, setTaskEquipmentList] = React.useState([])
    const [requestBySupplier, SetRequestBySupplier] = React.useState([])
    const [graphData, setGraphData] = React.useState()
    const [toolbarState, setToolbarState] = React.useState({
        supplier: '',
        equipment: ''
    })
    const equipmentByID = keyBy(equipmentList, '_id')
    const supplierById = keyBy(suppliersList, '_id')


    const onResetToolbarState = () => setToolbarState({
        supplier: '',
        equipment: ''
    })

    const onToolbarStateChange = ({ name, value }) => {
        console.log(name)
        console.log(value)
        setToolbarState((prev) => ({ ...(prev ?? {}), [name]: value }))
    }

    React.useEffect(() => {
        const filter = [{ status: true }]
        let demo
        if (toolbarState.from_date) {
            demo = { timeStamp: { $gte: new Date(toolbarState.from_date).getTime() } }
            filter.push(demo)
        }
        if (toolbarState.to_date) {
            demo = { timeStamp: { $lte: new Date(toolbarState.to_date).getTime() } }
            filter.push(demo)
        }
        const raw = {
            "filter": filter
        }
        Apis.getTaskEquipmentByFilter(raw).then((res) => {
            setTaskEquipmentList(res.data.data.taskequipments)
        }).catch((error) => {
            console.log(error)
        })

    }, [toolbarState])

    React.useEffect(() => {
        const listed = [];
        taskEquipmentLists.map((list, key) => {
            console.log(list)
            var demo = {
                id: list._id,
                equipment: list.equipment,
                supplier: equipmentByID[list.equipment]?.supplier,
                date: new Date(list.timeStamp).setHours(0, 0, 0, 0),
                quantity: list.quantity,
                sub_quanity: [list.quantity]

            }
            var index = findArryIndex(listed, demo);
            if (index === -1) {
                listed.push(demo)
            } else {
                listed[index].quantity = parseInt(listed[index].quantity) + parseInt(list.quantity)
                listed[index].sub_quanity.push(list.quantity)

            }

            return listed
        })
        SetRequestBySupplier(listed)

    }, [taskEquipmentLists])
    React.useEffect(() => {
        const list = []
        if (requestBySupplier.length !== 0) {
            requestBySupplier.map((elm) => {
                if (toolbarState.supplier !== '') {
                    if (toolbarState.supplier === elm.supplier) {
                        var demo = {
                            [elm.equipment]: elm.quantity,
                            startDate: elm.date,
                            equipment: elm.equipment,
                            supplier: elm.supplier,
                            quantity: elm.sub_quanity
                        }
                        list.push(demo)
                    }
                } else {
                    var demo = {
                        [elm.equipment]: elm.quantity,
                        startDate: elm.date,
                        equipment: elm.equipment,
                        supplier: elm.supplier,
                        quantity: elm.sub_quanity
                    }
                    list.push(demo)
                }
                return list
            })
        }

        setGraphData(list)
    }, [requestBySupplier, toolbarState.supplier])


    const labelFormatter = (value) => equipmentByID[value]?.material_name


    const CustomTooltip = ({ payload, label, active }) => {
        if (payload) {
            var data = payload[0]
            var html = '';


            if (data) {
                if (active) {
                    var sum_quantity = data.payload.quantity
                    var length = sum_quantity.length
                    length = length - 1
                    sum_quantity.map((elm, key) => {
                        html += elm;
                        if (key !== length) {
                            html += "+";
                        }

                    })
                    return (
                        <div className="custom-tooltip ">
                            <div className="tooltip-body">
                                <p className="label mb-1">{labelFormatter(label)}</p>
                                <p className="mb-0" style={{ color: data.fill }}>{equipmentByID[data.name]?.equipment + ', ' + supplierById[data.payload.supplier]?.company_name}</p>
                                <p className="mb-0" style={{ color: data.fill }}>{ } <span style={{ color: '#000' }}>{': ' + payload[0].value}</span></p>
                                <p>{'(' + html + ')'}</p>
                            </div>
                        </div>
                    );
                }
            }
        }

    }



    return (
        <>
            <Row>
                <Col className='col-lg-12 mb-5'>
                    <Row>
                        <Col className='col-lg-9'>
                            <RequestByTeamToolbar
                                suppliers={suppliersList.map((i) => ({ value: i._id, label: i.company_name }))}
                                equipment={equipmentList.map((i) => ({ value: i._id, label: i.equipment }))}
                                onChange={onToolbarStateChange}
                                state={toolbarState}
                            />
                        </Col>
                        <Col className='col-lg-3 mt-3'>
                            <div className='container-fluid'>
                                <Row className=" align-self-end justify-content-end">
                                    <Col className="col-lg-6 d-flex justify-content-end ">
                                        <Pdf targetRef={ref} filename={"Material-Request-By-Team-" + new Date().toDateString() + ".pdf"} options={options} x={.6} y={.6} scale={0.7}  >
                                            {({ toPdf }) => <Button className='btn btn-success' onClick={toPdf}>Generate Pdf</Button>}
                                        </Pdf>
                                    </Col>
                                    <Col className="col-lg-6 " style={{ display: 'contents' }}>
                                        <Button
                                            color="danger"
                                            className="btn-primary btn waves-effect waves-light"
                                            onClick={onResetToolbarState}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                </Col>
                <div ref={ref} >
                    <Col className='col-lg-12 text-center '>
                        <Card>
                            <Card.Body>
                                <div style={{ height: '400px' }}>
                                    <ResponsiveContainer width="100%" height="100%" ref={wrapperRef}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={graphData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid stroke='#f8f8f8' vertical={false} />
                                            <XAxis
                                                dataKey="startDate"
                                                domain={["dataMin", "dataMax"]}
                                                tickFormatter={dateFormatter}
                                                axisLine={true}
                                                /*  tick={XAxisTick} */
                                                /* height={70} */
                                                fill="#5b626b"
                                                tickLine={false}
                                                padding={XAxisPading}
                                                interval="preserveStartEnd"
                                            />
                                            <YAxis
                                                /* tickCount={10}*/
                                                axisLine={false}
                                                tickLine={false}
                                                type="number"
                                            />

                                            <Tooltip
                                                content={<CustomTooltip />}

                                            />
                                            <Legend
                                                wrapperStyle={LegendWrapperStyle}
                                                verticalAlign="top"
                                                formatter={(value) => equipmentByID[value]?.equipment}
                                            />
                                            {equipmentList && equipmentList.map((t) => {
                                                if (toolbarState.equipment !== '') {
                                                    if (toolbarState.equipment === t._id) {
                                                        return (
                                                            <Bar
                                                                key={t._id}
                                                                dataKey={t._id}
                                                                fill="#8884d8"
                                                            />
                                                        )
                                                    }
                                                } else {
                                                    return (
                                                        <Bar
                                                            key={t._id}
                                                            dataKey={t._id}
                                                            fill="#8884d8"
                                                        />
                                                    )
                                                }
                                            })}

                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </div>

            </Row>
        </>
    )
}

export default RequestBySupplier